/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';


form span.error {
  width: 100%;
  font-size: 0.857rem;
}

form .error:not(input) {
  color: #ea5455;
}

form input.error,
form input.error:focus {
  border-color: #ea5455 !important;
}

form textarea.error {
  border-color: #ea5455 !important;
}

form .select2.error+.select2-container--classic .select2-selection--single,
form .select2.error+.select2-container--default .select2-selection--single,
form .select2.error+.select2-container--classic .select2-selection--multiple,
form .select2.error+.select2-container--default .select2-selection--multiple {
  border-color: #ea5455 !important;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'] {
  background-position: right 1rem center, center right 2.5rem;
}


html {
  font-size: 0.9rem !important;
  font-family: 'gilroy' !important;
}

h1,
h2,
h3,
h4,
h5,
p,
li {
  font-family: 'Gilroy' !important;
}

html body {
  background-color: #ffffff !important;
}

.auth-inner {
  height: 100vh;
}

.collapsed {
  width: 50px !important;
}

li {
  list-style: none;
}

@media(max-width:1500px) {
  .header-lg {
    width: 95% !important;
  }
}


.danger {
  color: #eb7676 !important;
}

a {
  text-decoration: none;
}



label {
  display: inline-block;
}

.form-label {
  margin-bottom: 0.2857rem;
  font-size: 0.857rem;
}

button:disabled {
  opacity: 0.5;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.forgot {
  color: #00b3fe;
}

.margin-top-3 {
  margin-top: 3px;
}

.w-90 {
  width: 90% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-1 {
  padding-left: 10px !important;
}

.mr-1 {
  margin-right: 7px;
}

.mr-2 {
  margin-right: 15px;
}

.ml-1 {
  margin-left: 8px;
}

.mt-15 {
  margin-top: 10px !important;
}

.ml-2 {
  margin-left: 15px;
}

.pl-2-5 {
  padding-left: 2.5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.w-25 {
  width: 25% !important;
}

.w-75 {
  width: 75% !important;
}

.nowrap {
  white-space: nowrap !important;
}

input.form-control:not(:focus),
select.form-select:not(:focus),
textarea.form-control:not(:focus) {
  border-color: #8f99aa;
}

input.form-control,
select.form-select,
textarea.form-control {
  background-color: #ffffff;
  color: #313e56;
}

.form-control:focus:valid,
.form-control:focus.is-valid {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}

.form-control:focus {
  border-color: #00b3fe;
  outline: 0;
}

input.form-control,
select.form-control,
textarea.form-control {
  background-color: #fff;
  color: #313e56;
}


.form-check-input:not(:checked):not(:indeterminate):not(:focus) {
  border-color: #818387;
}

.form-check-input:not(:checked):not(:indeterminate) {
  background-color: #fff;
}

.form-check:not(.form-switch) .form-check-input[type="checkbox"]:checked {
  background-size: 65%;
}

.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgba(115, 103, 240, 0.4);
}

.form-check-input:checked {
  background-color: #00b3fe;
  border-color: #00b3fe;
}


.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3e%3cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3e%3c/svg%3e");
}


.overlay {
  width: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
  height: 100%;
  display: none;
  transition: 0.3s;

  &.mobile-open {
      display: block;
  }
}

@media (max-width: 992px) {
  .p-lg-5 {
    padding: 4rem !important;
  }

  .sidebar-area{
        position: fixed !important;
        z-index: 1000 !important;
        left: -2000px !important;

        &.mobile-open {
          left: 0 !important;
          background: #fff;
      }
  }
}

.card {
  box-shadow: none !important;
  border-bottom: 1px solid #ebe9f1;
}

.modal-dialog.modal-dialog-centered {
  margin-right: auto !important;
}

p.card-text.mb-2 {
  color: #52617e !important;
}

label.form-check-label {
  color: #313e56;
}
.font-small-3 {
  color: #313e56;
}

::ng-deep .btn-light {
  color: #606776;
}


.error {
  color: #e41c0e !important;
}

input.form-radio {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.card {
  box-shadow: none !important;
  border: 1px solid #cdcdcd !important;
}

.mat-mdc-raised-button.add-course-btn:hover {
  background-color: #00b3fe !important;
  color: #fff !important;
  box-shadow: none !important;
}

.card mat-card-content {
  padding: 1.5rem 1.5rem !important;
}

.card-header {
  padding: 1.5rem 1.5rem 1.5rem 2.4rem !important;

  .mat-mdc-card-header-text {
    display: none !important;
  }
}

.autocomplete-customizations {
  width: 150px !important;
}

.autocomplete>div {
  background: #fff !important;
  padding: 7px;
}

.autocomplete>div:hover {
  background: #f3f3f3 !important;
}

.autocomplete {
  border-radius: 3px;
  border: 1px solid #cdcdcd !important;
  box-shadow: 0px 1px 6px -3px #000;
  padding: 5px 0;
  z-index: 1000;
}

.mat-mdc-checkbox label {
  text-align: left;
}

.main-section {
  align-items: center;

  .filter-input-section {
    width: 300px;
    text-align: center;
    background: white;
    margin-top: 16px;
    border-radius: 6px;
    position: absolute;
    top: 20px;
    z-index: 1000;
    padding-bottom: 20px;
    border: 2px solid #cdcdcd;
  }

  .filter-content {
    max-height: 250px;
    overflow-y: auto;
  }

  .radio-input-padding {
    padding: 10px 35px;
  }

  .filter-contains-title {
    width: 100%;
    background: #363636;
    color: #fff !important;
    padding: 15px;
    text-align: left;
    font-weight: 400;
    margin-bottom: 20px;
    font-size: 17px;
  }

  .active-filter {
    background-color: #3197FF !important;
    color: #fff !important;
  }

  .contains-field {
    width: 85%;
    border: none;
    border-bottom: 1px solid #727272;
    outline: none;
  }

  .contains-radio {
    width: 100%;
    height: 21px;
  }

  .contains-check-label {
    margin-top: 1px;
  }

  .contains-checkbox {
    width: 20px !important;
    height: 20px !important;
    margin-top: 2px !important;

  }

  .contains-label {
    font-weight: 500;
  }

  .contains-field:focus {
    border-bottom: 2px solid #3f3f3f;
  }

  .filter-controls {
    padding: 15px 20px 0;
    margin-top: 20px;
    border-top: 1px solid #b5b5b5;

    .apply-label {
      color: #00b3fe;
    }

    span {
      font-weight: 500;
    }
  }
}

.autocomplete-input {
  border: none;
}

.autocomplete-input:focus {
  border: none;
  outline: none;
}

.chip {
  padding: 4px 10px;
  background-color: #e5e5e5;
  color: #727272;
  margin-right: 5px;
  border-radius: 12px;
  font-size: 13px;
  font-weight: 500;

  svg {
    width: 17px;
    margin-top: -1.5px;
    margin-left: 5px;
  }
}

.chip:hover {
  background-color: #d6d6d6;
}

.new-msg-body {
  height: calc(100vh - 368px);
  overflow: auto;
}

// SIDEMENU CODE
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0 0;
}

.mat-drawer-container {
  position: static !important;
  height: 100%;
  width: 270px;
  transition: 0.3s;
  border-right: 0;
}

.mat-drawer-side {
  border-right: 0;
}

.mat-drawer {
  position: relative !important;
  height: 100%;
}

.mat-drawer-backdrop {
  display: none !important;
}

.navigation .mat-mdc-nav-list a svg {
  height: 20px;
  width: 20px;
  font-size: 11px;
  margin-right: 1.38rem;
}

.navigation .mat-mdc-list-item a svg,
.navigation .mat-mdc-list-item a mat-icon {
  height: 20px;
  width: 20px;
  font-size: 11px;
  margin-right: 1.38rem;
}

.navigation .mat-mdc-list-item a mat-icon {
  font-size: 20px !important;
}

.fixed-menu {
  border-top: 1px solid #cdcdcd;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.main-menu {
  overflow: hidden !important;
  transition: 0.3s;
  box-shadow: none !important;
}

.main-menu.half {
  width: 80px;

  .navigation li a svg {
    margin-right: -10rem !important;
    width: 25px;
    height: 21px;
  }

  .main-menu.menu-light .navigation li a {
    padding: 10px 15px 10px 13px;
  }

  #apps-pages {
    display: none;
  }

  .brand-text {
    display: none;
  }
}

.side-menu-width {
  width: 270px;
  display: block;
  min-height: 91vh;
  height: 100%;
}

.sidebar-area {
  .mat-mdc-list-base {
    --mdc-list-list-item-hover-state-layer-color: #fff;
  }

  .mat-mdc-list {
    border-radius: 10px;
  }
}

@media (max-width:1199px) {
  .main-menu.mobile-view {
    display: none;
  }

  .main-menu {
    position: absolute;
    box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
  }

  .main-menu.slide-left {
    transform: translateX(-260px);
  }

  .main-menu .navbar-header {
    width: 287px !important;
  }
}

@media (max-width:768px) {
  .main-menu {
    position: fixed;
    left: 241px;
    transition: 0.4s;
    margin-right: 1.1rem;
  }
}

.navbar-header.expanded {
  height: 100px;
  margin-bottom: 10px;
  width: 100%;
}

.logo {
  width: 170px;
  margin: 0 auto;
  margin-top: 30px;
}

.main-menu .navbar-header .navbar-brand {
  margin-top: 0 !important;
}

.main-menu .navigation-main.scroll-open {
  overflow-y: auto;
  height: 100%;
}

.main-menu.menu-light .navigation>.mat-mdc-list-item.active a {
  background: #f3f3f3 !important;
  font-weight: 400
}

.main-menu .main-menu-content::-webkit-scrollbar {
  width: 4px;
}

.main-menu .main-menu-content::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}

.main-menu .main-menu-content::-webkit-scrollbar-thumb {
  background-color: #7e7e7e;
  border-radius: 40px;
}


@media (max-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }
}

.mat-mdc-menu-item {
  min-height: 36px !important;
}

// AUTH
.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #2ebaf5 !important;
}

//ICON RIPPLE

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 32px !important;
  height: 30px !important;
  padding: 0 !important;
  margin-left: 30px;
}


.add-popup-body::-webkit-scrollbar,
.bundle-popup::-webkit-scrollbar {
  width: 6px;
}

.popup-footer {
  border-top: 1px solid #cdcdcd;

  button {
    background: #2196f3;
    color: #fff !important;
    border: none;
    outline: none;
    padding: 10px 25px;
    font-weight: 500;
  }
}

button:disabled {
  opacity: 0.5;
}

.visibility-sub-header {
  color: #888;
  font-size: 15px;
}

button.country-code-picker {
  .mdc-button {
    min-width: 83px;
  }
}


// 
.product-base-header {
  padding: 0 34px;
}

a:not([href]).category-btn {
  color: rgb(226, 41, 17);
  font-size: 18px;
  margin-top: 3px;
  font-weight: 500;

  :hover {
    color: rgb(226, 41, 17);
  }
}

.tabs-container {
  position: sticky;
  top: 0.5px;
  background: #fff;
  z-index: 9;
  border-bottom: 0.5px solid #d0d0d0;
  
  a {
    color: #797979;
    font-weight: 700 !important;
    padding: 4px 15px;
    font-size: 14px;
    letter-spacing: 0px;
    white-space: nowrap;
    transition: all .2s;
    border-bottom: 2px solid #3198ff00;
    margin-bottom: 10px;

    &:hover{
      background-color: rgba(154, 169, 190, 0.1);
      border-radius: 10px;
      transition: all .2s;
    }
  }
  
  a.active {
    color: #141414;
    padding: 4px 15px;
    margin-bottom: 0;
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid #3197FF;
    font-size: 14px;
    font-weight: 700 !important;
    
    &:hover{
      padding: 4px 15px;
      border-bottom: 2px solid #3198ff00;
      margin-bottom: 10px;
      border-radius: 10px
    }
  };

}


.status {
  margin-left: 6px;
}

.user-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}



.mat-mdc-paginator-page-size {
  margin: 0 !important;
}





///////////////////////////////TRADE NEXT STYLE START ///////////////////////////////
/// 
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans_SemiCondensed-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans_SemiCondensed-Regular.ttf');
  font-weight: 400;
}


@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans_Condensed-Medium.ttf');
  font-weight: 500;
}


@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Regular.ttf');
  font-weight: 400;
}

;

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Medium.ttf');
  font-weight: 500;
}


@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Bold.ttf');
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gilroy' !important;
}

.w-lg-50 {
  width: 50%;
}

.p-15 {
  padding: 10px;
}

// LOADER EFFECT

/* ALL LOADERS */

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

/* LOADER 2 */

.loader-2 span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #3498db;
  margin: 35px 5px;
}

.loader-2 span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

.loader-2 span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

.loader-2 span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {

  0%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

.loader-overlay {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

//BUTTON
.btn {
  width: 100% !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  color: #fff !important;
  padding: 13px 0 !important;
  height: 46px !important;
  font-family: 'Gilroy';
  text-transform: uppercase;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  text-transform: capitalize;
}

.add-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(236, 236, 236);
  border: 1px dashed #cbcbcb;
  text-align: center;

  mat-icon {
    color: #9f9f9f;
    height: auto !important;
    line-height: 70px;
  }
}

/* Add this to your component's styles or global styles */
::ng-deep .mat-stepper-horizontal .mat-step-header:not(.mat-step-header-selected):not(.mat-step-header-disabled) {
  background-color: transparent !important;
}

::ng-deep .mat-stepper-horizontal .mat-step-header:not(.mat-step-header-selected):not(.mat-step-header-disabled) .mat-step-icon {
  background-color: #fff;
  color: #000;
}

.mb-20 {
  margin-bottom: 20px;
}

.blue-btn {
  background-color: #3197FF !important;
  border: #3197FF !important;
}

.google-btn {
  background-color: #fff !important;
  padding: 15px 25px !important;
  box-shadow: 0px 0px 8px hsla(0, 0%, 0%, 0.168);
  border: 0 !important;
  border-radius: 6px !important;

  img {
    width: 16px;
    height: 16px;
  }

  h3 {
    color: #141414;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 10px;
    text-transform: uppercase;
    letter-spacing: 0;
  }
}

.deep-blue-btn {
  background-color: #2676C8 !important;
  border: #2676C8 !important;
}

.grey-btn {
  background-color: #797979 !important;
  border: #797979 !important;
}

.svg-icon-border-btn {
  padding: 3px;
  border-radius: 4px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #3b9cff;
    transition: all .3s;

    .svg-icon-icon {
      fill: #3b9cff;
    }
  }
}

.svg-icon-icon {
  fill: #9AA9BE;
  transition: all .3s;
}

.active-svg-icon-icon {
  fill: #fff;
}

/// status btn 
.status-btn {
  border: none !important;
  margin-left: 0 !important;

  p {
    font-size: 11px !important;
  }
}

.yet-progress {
  background-color: #d3e9ff !important;
  color: #3197ff !important;
  border: none !important;

}

.yet-progress .svg-icon path {
  fill: #3197ff !important;
}

.yet-start {
  background-color: #efefef !important;
  color: #909090 !important;
  border: none !important;
}

.yet-start .svg-icon path {
  fill: #909090 !important;
}

.on-hold {
  background-color: #ffead6 !important;
  color: #ffa34d !important;
  border: none !important;

}

.on-hold .svg-icon path {
  fill: #ffa34d !important;
}

.delivered {
  background-color: #d6ffe6 !important;
  color: #00BA5C !important;
  border: none !important;

}

.delivered .svg-icon path {
  fill: #00BA5C !important;
}

//STROKE
.strok-blue-btn {
  border: 1px solid #3197FF !important;
  color: #3197FF !important;
}

.grey-stroke-btn {
  border: 1px solid #797979 !important;
  color: #797979 !important;
}

.deep-stroke-btn {
  border: 1px solid #2676C8 !important;
  color: #2676C8 !important;
}

.label-btn {
  h3 {
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 8px;
  }
}

.deep-blue-label {
  color: #2676C8;
}

.trader-label {
  color: #121971;
}

.purple-label {
  color: #8F00FF;
}

.grey-google {
  background-color: #F6F6F6;
}

.sorting-btn {
  width: 120px !important;
  height: 24px !important;
  margin-left: 15px;
}

.strok-blue-btn.sorting-btn {
  border-color: #9AA9BE !important;

}

.btn,
.finance-btn {
  .svg-icon-icon {
    fill: #3197FF !important;

  }
}


.active-sorting-btn {
  background-color: #3197FF !important;
  color: #fff !important;
}

.back-btn {
  width: auto !important;
  padding: 0 5px !important;
  border: transparent !important;
  font-weight: 700;
  font-size: 14px;
  font-family: "Gilroy";
  text-transform: uppercase;
  letter-spacing: 1px !important;

}

.down-arrow-icon {
  width: 24px;
  height: 24px;
}

//INPUT
.caution {
  color: #FF0000;
  font-size: 12px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #2676C8;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #6a6a6a;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #6a6a6a;
}

.mdc-text-field:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
  color: #2676C8;
}

.textarea-info {
  font-size: 12px;
  color: #797979;
}

.unit-section {
  top: 0;
  right: 0;

  .unit-selection {
    width: 170px;

    &:focus-visible {
      outline: none;
    }
  }
}

.invoice-tax-width {
  .unit-section {

    .unit-selection {
      width: 100px;
    }
  }
}

.red {
  color: #FF0000;
}

.rotate180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.tax-select {
  width: 100%;

  .mat-mdc-select-value {
    width: 45%;
  }

  .invoice-table-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .invoice-table-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .invoice-table-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border: none;
  }

  .invoice-table-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .invoice-table-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .invoice-table-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    display: none !important;
  }

  .invoice-table-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .invoice-table-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .invoice-table-base .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    display: none !important;
  }
}

.invoice-add-page {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field {
    margin-bottom: 10px;
  }

  .custom-textarea .mat-input-element {
    height: 56px !important;
  }
}

.mobile-number-field {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    background-color: #fff;
    // height: 24px;
    // line-height: 24px;
  }

  button {
    height: 24px !important;
    white-space: nowrap;
  }


  // .mat-mdc-button .mdc-button__label, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-outlined-button .mdc-button__label{
  //   z-index: 0;
  // }
}

// PAGINATOR
.mat-mdc-paginator-page-size-label {
  color: #848484;
  font-family: 'Gilroy';
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #2676C8;
}

.mat-mdc-paginator-range-label {
  color: #6a6a6a;
}

::ng-deep .mat-paginator {
  border-color: red; // Change this to your desired color
}

//INVOICE PREVIEW
.invoice-details {
  font-size: 18px;
  font-weight: 700;
}

.customer-address {
  width: 80%;
  font-size: 16px;
  padding-bottom: 0px;
  font-weight: 400;
  margin-bottom: 0;
}

.invoice-right-side {
  font-size: 16px;
  font-weight: 600;
}

.country-code-container {
  display: flex;
  align-items: center;
}

.sku-list {
  padding: 10px 15px;
  cursor: pointer;
}

.items-ul {
  min-height: 50px;
  max-height: 243px;
  overflow: scroll;
}

.tax-field {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.country-code {
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.87);
  /* You can adjust the color as needed */
  // font-weight: bold;
}

.mat-mdc-form-field-error-wrapper {
  transform: translateY(-54%) !important;
  padding: 0 !important;
  // display: block !important;
}

//textarea
textarea {
  resize: horizontal;
  overflow: hidden;
}

.addres-field {
  border: 1px solid #141414;
  padding: 16px;
  border-radius: 4px;
  min-height: 120px;
  transition: all .1s;
}

.addres-field:focus-visible {
  outline: 2px solid #3197FF;
  border: 0;
}

//checkbox
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-hover-icon-color: #130f0f !important;
  --mdc-checkbox-selected-focus-icon-color: #797979 !important;
  --mdc-checkbox-selected-icon-color: #797979 !important;
  --mdc-checkbox-selected-pressed-icon-color: #797979 !important;
}

//TABLE
.ngx-otp-input {
  border-radius: 4px !important;
  border: solid 1px #c5c5c5 !important;
  text-align: center !important;
  font-size: 32px !important;
  width: 40px !important;
  height: 40px !important;
}

.ngx-otp-input-container {
  justify-content: center !important;
  align-items: center !important;
}

.company-profile {
  .mat-mdc-checkbox .mdc-checkbox__ripple {
    display: none;
  }

  .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
    display: none;
  }

  .mdc-checkbox__background {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  .mdc-form-field {
    font-size: 16px !important;
    color: #797979;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
    top: calc((var(--mdc-checkbox-state-layer-size, 35px) - 18px) / 2);
    left: 0;
  }

  .mdc-form-field>label {
    padding-left: 0;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #797979;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #797979;
    background-color: #797979;
  }

  .branch-name {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .delete-btn {
    color: #3197FF !important;
    letter-spacing: 0;
    font-size: 16px;
  }
}

.forget-password {
  color: #3197FF;
  font-size: 14px;
}

.tooltip-amount {
  display: none;
  top: 103%;
  left: 0;
  // background-color: #f6f6f6;
  // padding: 5px;
  // border-radius: 5px;
  // p{
  //     margin-bottom: 0;
  // }
}

.tooltip-relative:hover .tooltip-amount {
  display: block;
}

//SEARCH INPUT FIELD
.search-area-width {
  width: 300px;
}

.search-input-from-list {
  border: 0;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  outline: none;
  padding: 4.5px 7px;
  padding-left: 25px;
  width: 100%;
}

.search-input-from-list::placeholder {
  font-family: 'Gilroy';
  font-size: 13px;
  color: #b8c1cf;
}

.search {
  width: 100%;
  background-color: #F7F7F7;
  padding: 10px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.search:focus-visible {
  outline: none;
}

.invoice-list-search {
  .search-input-from-list {
    padding-left: 20px;

  }
}

.search-icon-btn {
  top: 10px;
  left: 5px;
  color: rgba(0, 0, 0, 0.12);
}

// .search-icon-btn.active {
//   opacity: 0;
// }
.search-cross-icon {
  top: 6px;
  right: 5px;
  color: #b7b7b7;
}

//popup heading
.popup-heading {
  color: #6a6a6a;
}

// GST BUTTON
.gst-btn {
  border: 1px solid #141414;
  height: 70px;
  width: 100%;
  // line-height: 70px;
  padding: 20px;
}

//MAT-MENU
.sorting-menu {
  .menu-title {
    padding: 6px;
    color: #212529;
    font-size: 14px;
    // text-align: center;
    cursor: pointer;
  }

  li {
    &:hover {
      background-color: #e2f6ff9e;
    }
  }

}

.sorting-menu button:hover {
  background-color: rgba(226, 246, 255, 0.6196078431);
}

.input-width {
  width: 50px;
  border: 0;
  border-bottom: 1px solid;

  &:focus-visible {
    outline: none;
  }
}

.menu-search {
  width: 99%;
  margin: 0 auto;

  .search-area-width {
    width: 100%;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-width: 120px !important;
  max-width: 600px !important;
}

.customer-menu-width.mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-width: 292px !important;
}

.sorting-menu-width {
  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    width: 300px !important;
  }
}

.sorting-menu {
  .menu-title {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

//LOGIN, SIGNUP PAGE
.cb-logo {
  width: 245px;
}

.login-page {
  h2 {
    font-size: 29px;
    font-weight: 400;
    color: #404040;
  }

  .login-h3 {
    font-size: 52px;
    color: #404040;
    font-weight: 500;
  }

  .register {
    font-weight: 700;
    font-size: 14px;
    color: #404040;
    letter-spacing: 1px;
  }

  .mdc-notched-outline {
    height: 52px !important;
  }

  .mat-mdc-form-field-infix {
    min-height: 52px;
  }

  .mdc-text-field .mdc-floating-label {
    top: 50% !important
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 14px 0;
  }

  .signup {
    font-weight: 700;
    font-size: 14px;
    color: #3197FF;
    letter-spacing: 1px;
  }

  // .mat-mdc-form-field-subscript-wrapper{
  //   display: none;
  // }

  .ngx-otp-input {
    width: 49px !important;
    height: 50px !important;
  }

  .otp-input {
    width: 15% !important;
    height: 61px !important;
  }
}

.resent-otp-btn {
  color: #3197ff;
  font-size: 15px;
  font-weight: 500;
  text-decoration: underline;
}

.dashed {
  background-color: #404040;
  width: 100%;
  height: 1.5px;
}

.or-word {
  font-weight: 700;
  font-size: 14px;
  color: #141414;
}

.enter-otp {
  color: #868686;
}

// HEADER

.header-select-box {

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-select-value {
    font-family: "Gilroy", sans-serif;
    font-size: 13px;
    color: #9AA9BE;
  }

  .mat-mdc-form-field-infix {
    min-height: auto;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 0;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #fff;
    border: #9AA9BE;
  }

  .mat-mdc-text-field-wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .mdc-text-field--outlined .mdc-notched-outline {
    //display: none;
    border: 1px solid #b7b7b7;
    border-radius: 4px;

  }
}

/* Custom CSS to always show the sorting icon */
.sort-icon-container .sort-icon {
  visibility: visible;
  opacity: 1;
  transition: none;
}

.progress {
  height: 10px !important;
  background-color: #e2e2e2;
}

.due-segment {
  height: 100%;
  background-color: red;
  /* Set color for due */
}

.in-progress-segment {
  height: 100%;
  background-color: orange;
  /* Set color for in progress */
}

.delivered-segment {
  height: 100%;
  background-color: green;
  /* Set color for delivered */
}

// working list select box
.pagination-border {

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.12);
  }

}

// TAB
.tab-nav-circle {
  //  content: "";
  display: block;
  height: 5px;
  //  border-radius: 10px;
  width: 90%;
  background-color: #dddddd;
  color: #f6fbff;
  margin: 0px;
  position: relative;
  z-index: 100;
}

.tab-name {
  h4.active {
    color: #2196f3;
  }
}

.tab-name::after {
  content: "";
  display: block;
  height: 2px;
  width: 25%;
  background-color: #2196f3;
  margin: 0px;
  position: absolute;
  z-index: 100;
  top: 21px;
  left: 46px;
}

.tab-nav-circle.active {
  //  content: "";
  display: block;
  height: 5px;
  //border-radius: 10px;
  width: 90%;
  background-color: #2196f3;
  margin: 0px;
  position: relative;
  z-index: 100;
}

.nav-line {
  display: block;
  width: 81.6%;
  margin: 0 auto;
  background: #90caf9;
  height: 2.5px;
  position: absolute;
  bottom: 6px;
  left: 89px;
}

.active-nav {
  width: 45px;
  height: 45px;
  background-color: rgb(0, 186, 92);
  border-radius: 50px;
  color: #fff;
  text-align: center;
  line-height: 45px;
}

.tab-width {
  width: 17%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-content {
  // height: 100vh;
  height: calc(100vh - 1.5rem);
  // height: calc(100vh - 10.4rem);
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.mat-mdc-list-base {
  --mdc-list-list-item-focus-state-layer-opacity: 0
}

.dash-margin {
  margin: 30px 0;
}

// CALENDER DESIGN
.fc-h-event {
  background-color: #26a1dd;
  border: transparent;
}

.fc-h-event-delivered {
  background-color: #dd262c !important;
  border-radius: 3px;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background: #26a1dd;
}

.fc .fc-toolbar-title {
  font-size: 17px;
}

.fc .fc-button-primary:disabled {
  background-color: #fff;
  color: #3197FF;
  border: none;
}

.fc .fc-button:disabled {
  opacity: 1;
  border: 1px solid #3197FF;

}

.fc .fc-button {
  padding: 3px;
}

.fc .fc-button-primary:hover {
  background-color: #3197FF;
  color: #fff;
  border: 1px solid #3197FF;
}

.fc .fc-button-primary {
  background-color: #fff;
  color: #3197FF;
  border: 1px solid #3197FF;
  text-transform: uppercase;
  font-size: 13px;
}

.fc .fc-button-primary:focus {
  box-shadow: none;
}

// .fc-event{
//   background-color: #26a1dd;
// a{
//   color: #ffffff;
// }
// }
.fc-daygrid-dot-event {
  display: block;
  background-color: #26a1dd;
  padding: 0 !important;
  color: #fff !important;
}

.fc-today-button {
  display: none;
}

.no-data-img {
  width: 250px;
}

/// TOOLTIP
.custom-tooltip {
  position: relative;
  padding: 5px;
  box-sizing: border-box;
  background: #4B4B4B;
  color: #fff;
  font-size: 13px;
  border-radius: 4px;
  z-index: 999;
  // box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
}

.custom-tooltip::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -0.5em;
  top: -0;
  left: 26%;
  box-sizing: border-box;

  border: 0.4em solid #4B4B4B;

  // border-color: transparent transparent #bada55 #bada55;

  transform-origin: 0 0;
  transform: rotate(132deg);

  // box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);
}

//SIGN UP
.signup-page {
  .mat-horizontal-content-container {
    padding: 0;
    padding-top: 8px;
    padding-right: 0.5px;
  }

  .mat-step-header .mat-step-icon-state-edit {
    background-color: #00BA5C;
    color: #fff;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
    top: 45px;
  }

  .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    top: 45px;
  }


  .otp-input {
    width: 40px !important;
    height: 40px !important;
  }

  .verify {
    .otp-input {
      width: 15% !important;
      height: 61px !important;
    }
  }
}

.signup-page,
.company-register-page,
.stepper-design {
  .mat-step-icon {
    width: 30px;
    height: 30px;
    background-color: #F6F6F6;
    box-shadow: 0 0 4px 0 hsla(0, 0%, 0%, 0.185);
    color: #797979;
    font-weight: 700
  }

  .mat-step-text-label {
    font-size: 14px;
    font-weight: 700;
    font-family: "Gilroy";
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
    padding: 7px 0 0
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
  [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {
    left: -10px;
  }

  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after,
  .mat-stepper-horizontal-line {
    border-top-color: #404040 !important;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
  [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
    right: -10px;
  }

  // .mat-step-header{
  //   .mat-step-icon{
  //     background-color: #F6F6F6;
  //     ;
  //   }
  // }
  .mat-step-label {
    color: #797979 !important;

  }

  .mat-step-label-selected {
    color: #141414 !important;
  }

  .mat-step-header {

    .mat-step-icon-selected {
      color: #fff;
    }
  }

  .mat-step-icon-selected {
    background-color: #00BA5C;
  }
}

.mat-step-header.cdk-program-focused {
  background-color: #fff !important;
}

.mat-step-header.cdk-focused {
  background-color: #fff !important;
}

.mat-step-header.cdk-keyboard-focused {
  background-color: #fff !important;
}

.mat-step-header:hover:not([aria-disabled]) {
  background-color: #fff !important;
}

.mat-step-header:hover[aria-disabled=false] {
  background-color: #fff !important;
}

.mat-ripple {
  display: none !important;
}

.reset-password {
  p {
    line-height: 24px;
    margin-bottom: 40px;
    font-size: 16px;
  }

  .reset-heading {
    font-weight: 700 !important;
    font-size: 32px;
    color: #141414;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .otp-input {
    width: 15% !important;
    height: 15% !important;
  }
}

//Rdio Button
.upload-text {
  font-weight: 700 !important;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 16px;
}

.upload-logo-btn {
  margin-top: 26px;
  margin-bottom: 35px;
}

.icon-btn {
  width: 74px;
  height: 74px;
  margin: 0 auto;
  position: relative;

  img {
    border-radius: 50%;
  }

  .delete-icon {
    color: #fff;
    position: absolute;
    top: 37%;
    left: 33%;
    //display: none;
  }

}

.icon-btn:hover::before {
  content: "";
  background-color: #ff2a2a75;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.image-holder {
  width: 74px;
  height: 74px;
  margin: 0 auto;
  margin-top: 26px;
  margin-bottom: 35px;
}

.small-uploaded-btn-icon {
  bottom: 3px;
  right: -11px;
  width: 35px;
  height: 35px;

  .icon-btn {
    width: 35px;
    height: 35px;
  }
}

.add-item-img {
  border-radius: 8.11px;
  border: 1px solid #141414;

  p {
    font-size: 16px;
    font-weight: 700;
    color: #3197FF;
  }
}

.customer-name-width {
  width: 118px;
}

.check-margin {
  margin-top: 22px;
}

.radio-btn-lebel {
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.radio-btn-design {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  border: 1px solid #797979;
}

.active-redio {
  border: 9px solid;
}

.active-gst {
  background-color: #e3f0ff4d;
  border-color: #2676C8;
}

//ERROR TOASTE
.error-toaste {
  padding: 10px;
  background-color: #FFE7E6;
  border: 1px solid #FF0000;
  border-radius: 8px;

  h4 {
    font-size: 14px;
  }
}

//MAT SELECT SEARCH
.mat-select-search-input {
  background-color: #F7F7F7 !important;
}

.mat-mdc-option[aria-disabled=true].contains-mat-select-search .mat-icon {
  margin-top: 10px;
  display: none;
}

.mat-mdc-icon-button .mat-mdc-button-ripple {
  display: none !important;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #f7f7f7 !important;
}


.mdc-list-item.mdc-list-item--selected {
  background-color: #E1F1FF !important;
  border-top: 0.5px solid #afdaff !important;
  border-bottom: 0.5px solid #afdaff !important;
}

//CHIPS
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: #388AEA !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: #fff !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing {
  color: #fff !important;
  opacity: 1 !important;

}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #000 !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #4B8FE0;
}

.mat-mdc-option[aria-disabled=true].contains-mat-select-search {
  z-index: 1;
}

// SCROLLBAR

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #7e7e7e;
  border-radius: 40px;
  height: 4px;
}

//DASHBOARD
.dashboard-page {
  .mdc-text-field--outlined {
    padding: 0 20px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 8px 0;
  }

  .mat-mdc-form-field-infix {
    min-height: 45px;
  }

  .mat-mdc-text-field-wrapper {
    height: 45px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-select {
    height: 30px;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #000;
  }

}

// TOGGLE BUTTON
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #388AEA;
  --mdc-switch-selected-handle-color: #388AEA;
  --mdc-switch-selected-hover-state-layer-color: #388AEA;
  --mdc-switch-selected-pressed-state-layer-color: #388AEA;
  --mdc-switch-selected-focus-handle-color: #388AEA;
  --mdc-switch-selected-hover-handle-color: #388AEA;
  --mdc-switch-selected-pressed-handle-color: #388AEA;
  --mdc-switch-selected-focus-track-color: #388AEA;
  --mdc-switch-selected-hover-track-color: #388AEA;
  --mdc-switch-selected-pressed-track-color: #388AEA;
  --mdc-switch-selected-track-color: #388AEA;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

//BUSINESS REGISTER
.company-register-page {
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
    width: 25%;
    margin: 0 auto;
  }

  // .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
  // .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  //   top: 45px;
  // }

  // .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  //   top: 45px;
  // }

  .mat-step-header .mat-step-icon-state-edit {
    background-color: #00BA5C;
    color: #fff;
  }
}


.done-page {
  h2 {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
  }

  p {
    font-size: 20px;
    color: #000;
    font-weight: 400;
  }
}

///// Inner Pages
.page-heading {
  margin-top: 15px;
  font-size: 28px;
  font-weight: 700;

}

//ITEMS MASTER
.mat-expansion-panel-header {
  padding: 15px 24px !important;
  height: auto;
}

.mat-expansion-indicator {
  background: #3197FF;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.mat-expansion-indicator::after {
  margin-left: 12px;
  margin-top: 7px;
  color: #fff;
}

.mat-expansion-panel-header.mat-expanded {
  height: auto;
}

.mat-expansion-panel {
  margin-bottom: 15px !important;
}

.active-tab-border {
  padding-top: 10px;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.168);
}

.item-image {
  width: 90px;
  height: 90px;
  border-radius: 4px;
  border: 1px solid #b9b9b9;
  padding: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.upload-item-img {
  width: 158px;
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.upload-item-img-hover {
  position: relative;

  .delete-icon {
    color: #fff;
    position: absolute;
    top: 42%;
    left: 40%;
    display: none;
  }
}

.upload-item-img-hover:hover::before {
  content: "";
  background-color: #ff2a2a75;
  width: 158px;
  height: 158px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-item-img-hover:hover {
  .delete-btn {
    display: block;
  }

}

.uploaded-item-img {
  width: 158px;
  height: 158px;
}

.uploaded-item-img-section {
  p {
    font-size: 16px;
    color: #3197FF;
  }
}

.upload-item-img {
  position: relative;
  width: 158px;
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-item-img-hover {
  position: relative;
}

.upload-item-img-hover:hover .delete-icon {
  display: block;
}

.upload-item-img-hover:hover::before {
  content: "";
  background-color: #ff2a2a75;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.reselect-img label {
  cursor: pointer;
}

.uploaded-item-logo {
  width: 21px;
  height: 21px;
}

.opened-accordian-all-page {
  box-shadow: 0 2px 4px 0 #00000024;
  border-top: 1px solid #E3E3E3;
}

//HEADING & SUBHEADING
.product-heading {
  h2 {
    font-size: 13px;
    margin-bottom: 0 !important;
    // font-weight: 700;
    color: #9AA9BE;
  }

  p {

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;

  }
}

.product-sub-heading {
  font-size: 15px !important;
  margin-bottom: 0 !important;
  font-weight: 700 !important;
  color: #141414 !important;
}

.change-customer {
  font-size: 12px !important;
  letter-spacing: 1px !important;
  color: #2676C8 !important;
  font-weight: 500 !important;
  height: auto;
}

.mat-mdc-menu-content {
  padding-bottom: 0 !important;
}

.mat-mdc-select-panel {
  padding-bottom: 0 !important;
  max-height: 296px !important;
}

.payment-reminder {
  color: #2676C8;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

}

//supplier-add page
.supplier-add {
  .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background: transparent;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #000000 !important;
    background-color: #ffffff !important;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    color: #000000 !important;
  }

  .mat-horizontal-content-container {
    padding-top: 0;
  }

}

.supplier-btn-page {
  button {
    letter-spacing: normal !important;
  }
}

.disabled-field {
  pointer-events: none;
  opacity: 0.5;
}

//supplier-details-page
.supplier-details-page {
  .mat-horizontal-stepper-header-container {
    //width: 50% !important;
    margin: 0 auto !important;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

.pointer-events-none-accordion {
  .mat-expansion-panel-header:not([aria-disabled=true]) {
    pointer-events: none;
  }

}

//transaction-list
.supplier-name {
  font-size: 17px !important;
  margin-bottom: 0 !important;
  font-weight: 700 !important;
  color: #141414 !important;
}

.icon-display-md {
  display: none;
}

.icon-display-none-md {
  display: block;
}

.pr-32 {
  padding-right: 32px;
}

.transaction-list {
  .btn {
    height: 24px !important;
    font-size: 12px;
    width: 195px !important;
    margin-left: 15px;
    // font-size: 14px;
    // font-weight: 700;
    // letter-spacing: 0;
  }

  .mat-expansion-indicator::after {
    transform: rotate(317deg) !important;
    color: #3197FF !important;
    margin-left: 10px !important;
  }

  .mat-expansion-indicator {
    border: 1px solid #3197FF;
    background: #fff;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: #fff;
  }

}

.invoice-list-btn {
  height: 48px !important;
  width: 200px !important;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-top: 30px;
}

.invoice-list {
  .mat-expansion-panel-header {
    align-items: baseline !important;
  }
}

//TOGGLE SIDENAV
// .main-content {
//   overflow: hidden;
//   min-height: 100vh;
//   transition: .2s;
//   padding-left: 270px;

//   &.active {
//     padding-left: 70px;
//   }
// }

//ACCORDIAN DEISGN
.accordian-all-page {
  //height: 74px;
  box-shadow: 0 0 4px 0 #00000024;

  .span-grey-arrow {
    display: none;
  }
}

.draft-badge {
  height: 20px;
  padding: 2px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E4F3FF;
  border: 1px solid #3197FF;
  margin-left: 10px;
  font-weight: 400;
  font-size: 11px;
}

;

.sell-list {
  .draft-badge {
    display: inline;
    margin-left: 0;
  }

  ;
}

.items-list-width {
  min-width: 450px;
}

.due-badge {
  background-color: #ffd6d6;
  border: transparent;
  height: auto;
  color: #ef0000;
}

.total-budget-badge {
  background-color: #ffead6;
  border: transparent;
  height: auto;
  color: #ffa34d;
}

.flag-badge {
  background-color: #d6ffe6;
  border: transparent;
  color: #00BA5C;
  height: auto;
}

.recieved-badge {
  height: auto;
  border: transparent;
  color: #008eff;
}

.red {
  color: #FF0000;
}

.product-heading {
  h2 {
    margin-bottom: 7px !important;
  }
}

.form-control {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #797979;
}

.form-control::placeholder {
  font-family: FontAwesome;
  font-size: 16px;
  color: #797979;
  padding: 10px;
}

.payment-btn {
  font-size: 14px !important;
}

.product-heading {
  h2 {
    margin-bottom: 7px !important;
  }
}

.accordian-border {
  border-top: 0.5px solid #d8d8d8;
}

.dot-btn {
  min-width: 36px;
}

.search {
  width: 100%;
  background-color: #F7F7F7;
  padding: 10px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  // position: absolute;
  // top: 0;
  // z-index: 9;
}

.search:focus-visible {
  outline: none;
}

.active-sorting-button {
  background-color: #3197FF !important;
}

.sorting-btn {

  .word-active {
    color: #ffffff !important;
  }
}

.payment-hover {
  .edit-delete-btn {
    display: none;
  }
}

.disabled-btn {
  background-color: transparent;
  border: 0;
}

.payment-hover:hover {
  .edit-delete-btn {
    display: block;
  }
}

.items-ul {
  min-height: 50px;
  max-height: 243px;
  overflow: scroll;
}

.customer-selection {
  top: 10px;
  left: 0;
}

.w-65 {
  width: 72% !important;
}

.sorting-btn {
  min-width: 100px !important;
  max-width: 150px !important;
}

.find-btn {
  width: auto !important;
}

.sell-sorting {
  text-align: left !important;
}

.accordian-heading {
  font-size: 14px;
  color: #797979;
}

.w-49 {
  width: 49%
}

.w-150 {
  width: 150px;
}
.w-15{
  width: 15%;
}

.checkbox-list {
  width: 10px;
  height: 10px;
  border-radius: 4px;
}


.w-16 {
  width: 16.6%;
}

.back-btn {
  text-transform: inherit !important;
  height: auto !important;
  padding: 0 !important;
}

.svg-list-icon {
  margin-right: 5px;
}

.svg-budget-icon {
  fill: #ffa34d;
}

.svg-revenue-icon {
  fill: #fbff00;
}

.svg-recieved-icon {
  fill: #008eff;
}

.svg-due-icon {
  fill: #ef0000;
}

.payment-badge {
  background-color: #d6fffe;
  border: transparent;
}

.status-badge {
  background-color: #e7e9ff;
  border: transparent;
}

.dots-btn {
  top: 4px;
  right: 0;
}

.create-password-input {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.date-color {
  font-size: 12px;
  color: #141411;
}

.padding-off-select-btn {
  .header-select-box {
    .mat-mdc-text-field-wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.btn-height-auto {
  .mat-mdc-unelevated-button .mat-mdc-button-touch-target {
    height: auto !important;
  }
}

//INVOICE PAGE
.attachment-btn {
  padding: 2px;
}

.attachment-btn:hover {
  padding: 2px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.add-member {
  height: 160px;
  //width: 318px;
  border: 1px solid #414141;
  border-radius: 6px;

  mat-icon {
    color: #414141;
  }

  p {
    color: #414141;
    font-size: 16px;
    font-weight: 700;
  }
}

.cdk-overlay-pane {
  max-width: 60vw !important;
}

.invoice-heading {
  color: #141414;
  font-size: 16px;
  font-weight: 600;
}

.invoice-mat {
  .mat-mdc-menu-content {
    padding-top: 0 !important;
  }
}

.id-number {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #141414;
  margin-bottom: 25px;
}

.invoice-id-heading {
  font-size: 16px !important;
}

.invoice-table-main-base {
  overflow: auto;

  .addres-field {
    min-height: 34px;
    padding: 8px;
    transition: all .1s;
  }
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px !important;
  height: 40px !important;
}

.invoice-table-base {
  table-layout: fixed;

  thead {
    background-color: #ecf0f35c;
  }

  // padding: 10px;
  .invoice-table-heading {
    font-size: 16px;
    font-weight: 700;
    color: #414141;
    padding: 15px 25px;
    text-transform: uppercase;
    width: 270px;
  }

  tbody {
    td {
      padding: 10px 25px 15px 25px;
      vertical-align: baseline;
    }

    tr {
      border-bottom: 0.5px solid #d8d8d8;
    }
  }

  .short-width-th {
    width: 150px;
  }

  .drag-drop-icon {
    padding: 15px;
    width: 75px;
  }

  .delete-row-btn {
    width: 65px;
  }

  .delete-row-td {
    padding: 0;
    text-align: center;
  }

  .total-width-th {
    width: 60px;
  }
}

.table-data-input {
  padding: 10px;

  // textarea {
  //   width: 100%;
  //   border: 1px solid #00b3fe;
  //   border-radius: 4px;
  // }

  textarea:focus {
    outline: none;
  }
}

.sorting-menu {
  .sell-sorting {
    .menu-title {
      text-align: left !important;
    }
  }
}

.wrapper-none {
  .mat-mdc-form-field-subscript-wrapper {

    display: none !important;
  }
}

.total-input-field {
  margin: 0 auto;
  margin-right: 0;
}

.unit-section {

  .unit-selection {
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
      display: none !important;
    }

    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
      display: none !important;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
      display: none;
    }
  }
}

.chips-select-field {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-bottom: 8px !important;
  }
}

// WORK ORDER
.payment-table {
  .table-heading {
    background-color: #f3f3f3;
    color: #6a6a6a;

    .payment-th {
      padding: 8px 15px;
      width: 25%;
    }
  }

  .table-body {
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.1411764706);
    border-bottom: 1px solid #ebebeb;

    .payment-td {
      width: 25%;
      padding: 8px 15px;
    }

  }
}

.sorting-btn {
  padding: 5px !important;
  height: auto !important;
  width: auto !important;
  min-width: auto !important;
  max-width: auto !important;
}

.list-codes {
  font-size: 13px !important;
  color: #9AA9BE;
}

.table-hover,
.table-header-area {
  white-space: nowrap;
}

.table-footer-row {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

// .mat-elevation-z8{
//   box-shadow: none !important;
// }
.table-hover:hover {
  background-color: #0386ff0a !important;
}

.mdc-data-table__content {
  font-family: 'Gilroy' !important;
}

.mdc-data-table__cell {
  font-family: 'Gilroy' !important;
  color: #9AA9BE;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #4b4b4b;
}

.statement.mat-elevation-z8 {
  box-shadow: none !important;
}

.mat-elevation-z8 {
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #D9D9D9;
    width: 16px;
    height: 16px;
    }
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple{
  background: transparent;
}

.work-order-summary {
  .net-sale {
    font-size: 15px;
    font-weight: 300;
    color: #9AA9BE;
    text-transform: uppercase;
    font-family: 'Open Sans' !important;
  }

  .net-sale-amount {
    color: #000;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      text-align: left;
      color: rgba(0, 0, 0, 0.33);
    }
  }

  .dashboard-white-box {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 0 10px 0px #d0d0d0;
    transition: all .1s;

    P {
      font-size: 13px;
      font-weight: 300;
      text-transform: uppercase;
      color: #9AA9BE;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
    }

    .details {
      font-size: 11px;
      font-weight: 600;
      line-height: 16.5px;
      text-align: left;

    }

    &:hover {
      transition: all .1s;

      p {
        color: #fff;
      }

      h2 {
        color: #fff;
      }

      .box-icon {
        border: 1px solid #fff;
        border-radius: 6px;
      }

      .details {
        color: #fff;
      }

      svg path {
        fill: #fff;
      }
    }

    ;
  }

  .box-icon {
    padding: 5px;
  }

  .budget-box {
    h2 {
      color: #FFBC11;
    }

    .box-icon {
      border: 1px solid #FFBC11;
      border-radius: 6px;
    }

    .details {
      color: #FFBC11;
    }

    svg path {
      fill: #FFBC11;
    }

    &:hover {
      transition: all .1s;
      background-color: #FFBC11;
    }

    ;
  }

  .active-budget-box {
    transition: all .1s;
    background-color: #FFBC11;

    p {
      color: #fff;
    }

    h2 {
      color: #fff;
    }

    .box-icon {
      border: 1px solid #fff;
      border-radius: 6px;
    }

    .details {
      color: #fff;
    }

    svg path {
      fill: #fff;
    }
  }

  .progress-box {
    h2 {
      color: #2FCD16;
    }

    .box-icon {
      border: 1px solid #2FCD16;
      border-radius: 6px;
    }

    .details {
      color: #2FCD16;
    }

    svg path {
      fill: #2FCD16;
    }

    &:hover {
      transition: all .1s;
      background-color: #2FCD16;
    }

    ;
  }

  .active-progress-box {
    transition: all .1s;
    background-color: #2FCD16;

    p {
      color: #fff;
    }

    h2 {
      color: #fff;
    }

    .box-icon {
      border: 1px solid #fff;
      border-radius: 6px;
    }

    .details {
      color: #fff;
    }

    svg path {
      fill: #fff;
    }
  }

  .delivered-box {
    h2 {
      color: #0386FF;
    }

    .box-icon {
      border: 1px solid #0386FF;
      border-radius: 6px;
    }

    .details {
      color: #0386FF;
    }

    svg path {
      fill: #0386FF;
    }

    &:hover {
      transition: all .1s;
      background-color: #0386FF;
    }
  }

  .active-delivered-box {
    transition: all .1s;
    background-color: #0386FF;

    p {
      color: #fff;
    }

    h2 {
      color: #fff;
    }

    .box-icon {
      border: 1px solid #fff;
      border-radius: 6px;
    }

    .details {
      color: #fff;
    }

    svg path {
      fill: #fff;
    }
  }

  .due-box {
    h2 {
      color: #FF3326;
    }

    .box-icon {
      border: 1px solid #FF3326;
      border-radius: 6px;
    }

    .details {
      color: #FF3326;
    }

    svg path {
      fill: #FF3326;
    }

    &:hover {
      transition: all .1s;
      background-color: #FF3326;
    }

    ;

  }

  .active-due-box {
    transition: all .1s;
    background-color: #FF3326;

    p {
      color: #fff;
    }

    h2 {
      color: #fff;
    }

    .box-icon {
      border: 1px solid #fff;
      border-radius: 6px;
    }

    .details {
      color: #fff;
    }

    svg path {
      fill: #fff;
    }
  }

  mat-progress-bar {
    height: 10px;
    /* Adjust height as needed */
  }

  .segment {
    height: 100%;
    position: absolute;
    top: 0;
  }

  .due-segment {
    background-color: #FF3326;
  }

  .in-progress-segment {
    background-color: #2FCD16;
    margin-right: 5px;
  }

  .delivered-segment {
    background-color: #0386FF;
    margin-right: 5px;
  }

  .calender {
    width: 20px;
    height: 20px;
  }
}




@media(max-width: 768px) {
  .responsive-row {
    flex-wrap: wrap-reverse;
  }

  .sorting-btn {
    min-width: auto;
    font-size: 12px;
    padding: 0px 5px !important;
  }

  .flex-md-column-btn {
    flex-direction: column-reverse !important;

    .mt-20-btn {
      margin-bottom: 15px !important;
    }
  }

  .mat-mdc-paginator-page-size-label {
    margin-top: 30px;
  }

  .flex-md-wrap {
    flex-direction: column;
  }

  .radio-btn-design {
    width: 24px;
    height: 24px;
  }

  .supplier-btn-page {
    button {
      letter-spacing: 1px !important;
    }
  }

  .dashboard-content {
    height: 100%;
  }

  .w-md-100 {
    width: 100%;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
    // width: 95%;
    width: 85%;
  }

  .pr-32 {
    padding-right: 0;
  }

  .signup-page {

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
      // width: 95%;
      width: 100%;
    }

    .upload-logo-btn {
      margin: 0;
      margin-bottom: 15px;

    }

  }

  .signup-page .mat-step-text-label,
  .company-register-page .mat-step-text-label {
    white-space: break-spaces;
    // font-size: 9px;
    font-size: 10px;
  }

  .payment-reminder {
    margin-bottom: 1rem !important;
  }

  .supplier-page-list {
    img {
      margin-bottom: 0;
    }
  }

  .add-item-img-md {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
  }

  .flex-column-md-arrow {
    flex-direction: column;
  }

  .grey-background-arrow {
    background-color: #F6F6F6;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .p-sm {
      padding: 10px;
    }

    .span-grey-arrow {
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      color: #2676C8;
      text-transform: uppercase;
      display: block;
      letter-spacing: 0.5px;
      margin-right: 16px;
    }
  }

  .search-input-from-list {
    width: 100%;
  }

  .icon-display-md {
    display: block;
  }

  .icon-display-none-md {
    display: none;
  }

  .details-page-accordian {
    .col-md-1 {
      padding: 0;

      .p-15 {
        padding: 10px 0;
      }
    }

    .justify-content-md-centers {
      justify-content: center !important;
    }

    .product-heading {
      p {
        margin-bottom: 10px !important;
      }
    }

    .flex-diection-reverse {
      flex-direction: column-reverse;
    }

  }

  .w-md-100 {
    width: 100% !important;
  }

  .login-page {
    .login-h3 {
      font-size: 25px;
      text-align: center;
    }

    h2 {
      font-size: 20px;
      text-align: center;
    }
  }

  .company-register-page {
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
      width: 100%;
      //   margin: 0 auto;
    }
  }

  .mat-expansion-panel-header {
    flex-direction: column !important;
  }

  .tabs-container {
    margin-top: 30px;

    a {
      margin-right: 0;
      font-size: 14px;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .transaction-list {
    .strok-blue-btn {
      .word {
        display: none;
      }

      width: 100% !important;
      margin-top: 10px;
      margin-right: 0 !important;
      margin-bottom: 10px;
      font-size: 13px;
    }
  }

  .btn-width {
    width: 100%;
  }

  .done-page {
    text-align: center;

    img {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .company-register-page {
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
      width: 57%;
    }

    .mat-horizontal-content-container {
      padding: 0;
    }

    .upload-logo-btn {
      margin: 0;
      margin-bottom: 15px;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
    .mat-vertical-stepper-header {
      padding: 10px 24px;
    }
  }

  .company-register-page .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    padding: 10px 12px;
  }

  //TOGGLE SIDENAV
  .main-content {
    padding-left: 0;
  }

  .responsive-side-text {
    margin-left: 0 !important;
  }

  .page-heading {
    font-size: 25px;
  }

  .unit-section {
    .unit-selection {
      width: 150px;
    }
  }

  .work-order-summary {
    .net-sale-amount {
      font-size: 23px;
    }
  }

  .search-area-width {
    width: auto;
  }

  .date-picker-custom {
    margin-left: 0 !important;
  }

  .flex-direction-sm {
    flex-direction: column;
  }

  .date-picker-custom {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .tabs-scroll {
    overflow: scroll;
  }

  .top-sm-1 {
    margin-top: 1rem !important;
  }

  .right-sm-1 {
    margin-right: 1rem !important;
  }

  .bottom-sm-1 {
    margin-bottom: 1rem !important;
  }

  .bottom-sm-0 {
    margin-bottom: 0 !important;
  }

  .start-sm-0 {
    margin-left: 0 !important;
  }

  .end-sm-0 {
    margin-right: 0 !important;
  }

  .top-sm-0 {
    margin-top: 0 !important;
  }

  .p-bottom-0 {
    padding-bottom: 0 !important;
  }

  .fc .fc-scrollgrid-section-body table {
    width: 100% !important;
  }

}
@media (max-width: 1400px){
.main-content {
  padding-left: 230px;
}
}

@media only screen and (min-width: 1200px) and (max-width: 1449px) {
  // .main-content {
  //   padding-left: 230px;
  // }

  .page-heading {
    font-size: 20px;
  }

  .page-heading {
    font-size: 17px;
  }

  .product-heading {
    h2 {
      font-size: 12px !important;
    }
  }

  .product-heading {
    p {
      font-size: 14px !important;
    }
  }

  .sku-list {
    font-size: 14px;
  }

  .btn {
    font-size: 12px !important;
  }

  .add-member {
    p {
      font-size: 14px;
    }
  }

  .id-number {
    font-size: 14px;
  }

  .invoice-table-base {
    .invoice-table-heading {
      font-size: 14px;
    }
  }

  .invoice-id-heading {
    font-size: 14px !important;
  }

  .invoice-details {
    font-size: 16px;
  }

  .customer-address {
    font-size: 14px;
  }

  .invoice-right-side {
    font-size: 14px;
  }

  .draft-badge {
    p {
      font-size: 11px !important;

    }
  }
}

@media (max-width:480px) {
  .modal-dialog {
    width: 100%;
  }


  .unit-section {
    .unit-selection {
      width: 115px;
    }
  }

  .dash {
    right: 36% !important;
  }
}


@media (min-width: 1399px) {
  // .container {
  //   // max-width: 1100px !important;
  //   padding: 0 20px;
  // }

  .work-order-summary {
    .net-sale-amount {
      font-size: 25px;
    }
  }

  ;

  .work-order-summary {
    .dashboard-white-box {

      h2,
      .dashboard-white-box {
        font-size: 17px;
      }
    }
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1399px) {
  .container {
    max-width: 1000px !important;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .container {
    max-width: 1100px !important;
  }
}

.date-picker-custom {

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: rgb(0 0 0 / 0%);
  }

  .cdk-program-focused {
    border-bottom-color: rgb(0 0 0 / 0%);
  }

  .mat-date-range-input-inner,
  .mat-calendar-body-cell-content,
  .mat-date-range-input-separator,
  .mat-date-range-input-inner::placeholder {
    color: #9AA9BE;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 18px;
    font-size: 14px;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 5px;
    padding-bottom: 8px;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    border: 1px solid #9AA9BE;
    border-radius: 4px;
    height: 34px;
    padding-top: 0px;
  }

  .mat-date-range-input-container {
    height: 25px;
  }

  .mdc-text-field .mdc-text-field__input {
    caret-color: #ffffff;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    height: 34px !important;
    margin-bottom: 25px;
    color: #9AA9BE;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: rgba(255, 255, 255, 0);
  }
}

.create-password {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;

  }
}


.date-picker-custom {

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: rgb(0 0 0 / 0%);
  }

  .cdk-program-focused {
    border-bottom-color: rgb(0 0 0 / 0%);
  }

  .mat-date-range-input-inner,
  .mat-calendar-body-cell-content,
  .mat-date-range-input-separator,
  .mat-date-range-input-inner::placeholder {
    color: #9AA9BE;
    font-size: 13px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 18px;
    font-size: 14px;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 5px;
    padding-bottom: 8px;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    border: 1px solid #9AA9BE;
    border-radius: 4px;
    height: 34px;
    padding-top: 0px;
  }

  .mat-date-range-input-container {
    height: 27px;
  }

  .mdc-text-field .mdc-text-field__input {
    caret-color: #ffffff;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    height: 34px !important;
    margin-bottom: 25px;
    color: #9AA9BE;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: rgba(255, 255, 255, 0);
  }
}

.credit-period {
  input {
    width: 87%;
  }

  .dashed {
    top: 8%;
    right: 15%;
    width: 1px;
    height: 57%;
    background-color: #6a6a6a;
  }

  .days {
    top: 23%;
    right: 4%;

    h5 {
      font-size: 16px;
      font-weight: 400;
      font-family: 'roboto' !important;
      color: #6a6a6a;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

}

.num-picker.mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-height: 200px !important;
  max-height: 300px !important;
  max-width: 350px !important;
}

.num-picker {
  .items-list-width {
    min-width: auto;
  }
}

.country-list.items-list-width {
  min-width: auto;
}

.country-list {
  .search-input-from-list {
    border: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #b7b7b7 !important;
    border-radius: 4px !important;
    outline: none !important;
    padding: 6px 7px !important;
    padding-left: 25px !important;
    width: 100% !important;
    height: 34px;
  }
}

.due-note {
  background-color: rgb(255 188 17 / 19%);
  border: 1px dashed #FFBC11;
  border-radius: 4px;
  padding: 15px;
  padding-bottom: 5px;
  padding-left: 5px;

  .margin-left-text {
    margin-left: 10px;
  }

  h4 {
    font-size: 14px;
  }

  .dash {
    width: 100% !important;
    height: 1px !important;
    background-color: #141414;
  }

  h5 {
    font-size: 14px;
    font-weight: 600;
  }

  .total-note {
    width: fit-content;
  }
}

.due-age {
  border: 1px solid #ef0000;
  background-color: transparent !important;
  color: #ef0000;
}

.issue-date {
  color: #9AA9BE;
}

.mdc-data-table__header-cell,
.mat-mdc-select,
mat-label,
mat-form-field,
mat-option,
.search,
mat-checkbox,
label,
.mat-mdc-snack-bar-container .mdc-snackbar__label,
.mdc-button,
.mat-calendar-body-cell-content,
.mat-calendar-table-header th {
  font-family: "Gilroy" !important;
}

.small-invoice {
  font-size: 14px !important;
  color: #9AA9BE;
  font-weight: 500 !important;
}

.w-43 {
  width: 43%;
}

.w-60 {
  width: 60%;
}

.w-2 {
  width: 2%;
}

.mat-mdc-select-arrow {
  color: #9AA9BE;
}

.account-number-value {
  font-weight: 700;
  color: #9AA9BE;
}

.acount-number-default {
  color: #3197FF;

}

.new-sorting-btn {
  padding: 5px 10px !important;
  font-size: 13px;
  font-weight: 500;
  color: rgb(154, 169, 190) !important;
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
  transition: all .3s;
  letter-spacing: 0 !important;
  height: 32px !important;
  svg {
    stroke: #9AA9BE;
  }
  
  &.active {
    color: rgba(46, 46, 46, 1) !important;
    transition: all .3s;
    
    h6{
      font-size: 13px;
      color: #3197FF;
    }
    svg {
      stroke: rgba(46, 46, 46, 1);
      transform: rotate(45deg);
    }
  }
}

.new-mat-menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-width: 228px !important;
  max-width: 228px !important;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0 4px rgba(0, 0, 0, 0.1);
  padding: 12px 10px !important;

  P {
    font-size: 13px;
    font-weight: 600;
    color: rgba(33, 37, 41, 1);
    letter-spacing: 0 !important;

  }

  .mdc-list {
    padding: 0 !important;
  }

  .header-select-box {
    .mat-mdc-text-field-wrapper {
      height: 28px !important;
      padding-top: 0px !important;
      padding-left: 10px !important;
      line-height: 25px;
      padding-bottom: 0 !important;
    }
     .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .header-select-box .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .header-select-box .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing{
      border: rgba(217, 217, 217, 1);
    }
     .mdc-text-field--outlined .mdc-notched-outline{
      border: 1px solid rgba(217, 217, 217, 1);
      border-radius: 3px;
     }
  }

  .btn {
    height: 35.2px !important;
    letter-spacing: 0 !important
  };
  
  .mat-mdc-select-value {
    font-weight: 500 !important;
    color: rgba(46, 46, 46, 1) !important;
  };

  .search-input-from-list{
    padding-block: 1.5px;
    font-size: 13px;
    color: rgba(33, 37, 41, 1);
  }
  .search-icon-btn{
    top: 6px;
  }

  .search-input-from-list::placeholder {
    font-size: 12px;
  }
  
  .mat-mdc-checkbox label{
    font-weight: 500;
    font-size: 13px;
    color: rgba(33, 37, 41, 1);
  }
  .search-cross-icon{
    top: 1.5px;
  }
  .items-ul{
    max-height: 200px;
  }

  .mat-mdc-checkbox.mat-accent{
    --mdc-checkbox-selected-hover-icon-color: #3197FF !important;
    --mdc-checkbox-selected-focus-icon-color: #3197FF !important;
    --mdc-checkbox-selected-pressed-icon-color: #3197FF !important;
    --mdc-checkbox-selected-icon-color: #3197FF !important;
  }
  .mdc-checkbox__background{
    width: 16px;
    height: 16px;
  }
}

.custom-height-menu.mat-mdc-option {
  min-height: 30px !important;
}

.custom-height-menu {
  .mdc-list-item__primary-text {
    font-size: 13px !important;
    font-weight: 500 !important;
  }

}

.date-picker-custom {
  .header-select-box {
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      padding-top: 0;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      height: 29px !important;
      margin-left: 0;
    }

    .mdc-icon-button svg {
      height: 22px !important;
    };

    .mat-mdc-form-field-icon-suffix{
      display: none;
    }

  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: transparent !important;
  }
   .mat-date-range-input-inner, .date-picker-custom .mat-calendar-body-cell-content{
    font-weight: 500 !important;
    color: rgba(46, 46, 46, 1) !important;
  }
  .mat-date-range-input-wrapper{
    max-width: calc(50% - 6px);
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled){
    color: rgba(217, 217, 217, 1);
  }
  .mat-date-range-input-container{
    justify-content: space-between;
  }

  .mat-mdc-text-field-wrapper {
    padding-right: 10px !important;
  }
   .mat-date-range-input-inner, .mat-calendar-body-cell-content{
      color: rgba(46, 46, 46, 1);
      text-align: center;
  }
  // , .mat-date-range-input-separator
  .mdc-text-field--filled:not(.mdc-text-field--disabled){
    border: 1px solid rgba(217, 217, 217, 1);
  }
}

.mat-mdc-raised-button:not(:disabled) {
  background-color: rgba(49, 151, 255, 1);
}
.main-layout {
  height: 100% !important;
}

.main-content-container {
  width: 100%;
  overflow: hidden;
}

.main-content {
  width: 100%;
  padding: 0 20px;
  overflow: hidden;
}

// SIDEBAR DESIGN
.sidebar-content {
  width: 270px !important;
  border-right: 1px solid #e6e6e6;

  transition: 0.3s ease;
  padding: 20px;

  .nav-collapse-btn {
      cursor: pointer;
      transition: 0.3s ease;

      &.rotate {
          transform: rotate(180deg) !important;
      }
  }

  .sidebar-main {
      width: 245px;
  }

  .logo {
      transition: 0.3s ease;
      padding-left: 5px;
  }

  .navigation {

      // margin-top: 40px;

      .nav-item {
          color: #000000;
          font-weight: 400;
          font-size: 14px;
          cursor: pointer;
          border-radius: 10px;
          padding: 10px;
          margin-bottom: 10px;
          transition: 0.3s ease;
          // width: fit-content;

          mat-icon {
              transition: 0.3s ease;
              margin-left: 10px;
              color: #a1b5b3;
          }

          span {
              transition: 0.3s ease;
          }

          .menu-icon {
              width: 36px;
          }

          svg {
              width: 23px;
          }

          svg.full {
              margin-right: 10px;

              path {
                  fill: #000;
              }
          }

          svg.border-only {
              path {
                  stroke: #000;
              }
          }

          &:hover {
              margin-left: 10px !important;
          }

      }

      .expandable-menu {
          height: 55px;
          overflow: hidden;
          transition: 0.3s ease;

          .expand-section {
              border-left: 2px solid #cccccc;
              padding: 0 20px;
              margin-left: 22px;

              h5 {
                  font-size: 14px;
                  font-weight: 400;
                  color: #000;
                  margin-top: 14px;
                  cursor: pointer;

                  &:hover {
                      color: #007D88;
                  }
              }

              ;

              .role-label {

                  h5 {
                      margin-top: 10px;

                  }
              }

          }

          .expand-section h5.active {
              color: #007D88;
          }

          &#team.expand {
              height: 130px;

              &.single-item {
                  height: 100px !important;
              }

              mat-icon {
                  transform: rotate(180deg);
              }
          }

          &#settings.expand {
              height: 135px;

              mat-icon {
                  transform: rotate(180deg);
              }
          }

          &#approvals.expand {
              height: 130px;

              &.single-item {
                  height: 100px !important;
              }

              mat-icon {
                  transform: rotate(180deg);
              }
          }

      }

      .nav-item.active {
          background: #36BEB033;
          color: #0A606F;
          font-weight: 500;
          padding: 10px !important;

          svg.full {
              path {
                  fill: #0A606F;
              }
          }

          svg.border-only {
              path {
                  stroke: #0A606F;
              }
          }
      }
  }

  &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
  }

  &::-webkit-scrollbar-track {
      background-color: rgb(255, 255, 255);
      border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border-radius: 40px;
  }

  &.close {
      width: 89px !important;

      .navigation {
          .nav-item {
              width: fit-content;

              span {
                  display: none !important;
              }

              mat-icon {
                  display: none;
              }

              svg.full {
                  margin-right: 0;
              }

              .menu-icon {
                  width: 24px;
              }
          }

          ;

          .expandable-menu {
              .expand-section {
                  display: none;
              }

              &#team.expand {
                  height: auto;
              }

              &#approvals.expand {
                  height: auto;
              }

              &#settings.expand {
                  height: auto;
              }
          }
      }

      .closed-btn {
          width: 44px;
          text-align: center;
      }
  }
}
// MENU OPTION

.new-menu-option{
  border-radius: 5px !important;
  box-shadow: 0px 0 4px rgba(0, 0, 0, 0.1) !important;

    h3{
      font-size: 13px;
      font-weight: 500;
      color: rgb(154, 169, 190);
      margin-bottom: 0 !important;
      padding: 15px 20px !important;
      padding-bottom: 0 !important;
    }

    .sku-list, .mat-mdc-menu-content{
      padding: 0 !important;
    }

    .items-ul{
      padding-bottom: 7px !important;
      padding-top: 7px !important;
      color: #3197FF;
      li{
      padding-inline:20px !important;
      font-size: 14px;
      font-weight: 600;
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important;
      line-height: 14px;
      transition: all .2s;

      &:hover{
      background-color: rgb(154 169 190 / 10%);
      transition: all .2s;
      }
      &:last-child{
      margin-bottom: 0;
      }
    }
    
    }
    .menu-border{
      width: 100%;
      height: 1px;
      background-color: #0000001A;
    }
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
  padding-top: 3px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper{
  height: 32px;
}


.tax-tds-overview {
  h2 {
      font-size: 16px;
      font-weight: 600;
      color: #000;
  }

  .dotted-border {
      border: 2px dotted #9EA8BC80;
      border-radius: 12px;
      padding: 15px;

      p {
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          color: #9EA8BC;
          width: 60%;
          margin: 0 auto;
          margin-top: 10px;
      }

      .tell-you {
          font-size: 16px;
          font-weight: 700;
          color: #1D1436;
      }

      h3 {
          font-size: 14px;
          font-weight: 500;
          color: #9EA8BC;

          span {
              font-weight: 700;

              color: #8F00FF;

          }
      }

  }

  .track-sale {
      h5 {
          font-size: 12px;
          font-weight: 500;
          color: #9EA8BC;
      }
  }

  .blue-monitor {
      padding: 10px;
      border-radius: 8px;
      background-color: #F0F8FF;

      h3 {
          font-size: 14px;
          font-weight: 700;
          color: #2E2E2E;
      }

      h4 {
          font-size: 13px;
          font-weight: 600;
          color: #6B6E7A;
      }

      h5 {
          font-size: 13px;
          font-weight: 700;
          color: #8F00FF;
      }

  }

  .para-loc{
    font-size: 12px;
    font-weight: 500;
    color: #9EA8BC;
  }


}

.tax-name.mat-sort-header-container{
  width: 100%;
  justify-content: end;
}
.result-text{
font-size: 16px;
font-weight: 600;
color: #9EA8BC;
}

.tax-form{

  .mat-mdc-radio-button.mat-accent{
    --mdc-radio-selected-focus-icon-color: #51ABFF;
    --mdc-radio-selected-hover-icon-color:#51ABFF;
    --mdc-radio-selected-icon-color:#51ABFF;
    --mdc-radio-selected-pressed-icon-color:#51ABFF;
    --mat-mdc-radio-checked-ripple-color: #51ABFF;
  
  };

  .mdc-form-field>label{
    font-size: 14px;
    font-weight: 700;
  }

  .tax-value{
    height: 36px;
  }

  .tax-value{
    .mat-mdc-text-field-wrapper{
      height: 36px;
    }
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
      top: 17px;
    }
    
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
      padding-top: 7px;
    }
  }
}

.leave-detail-backdrop {
  background: rgba(0, 0, 0, .5) !important;
}

@media (max-width: 1550px) {

  body,
  html {
      height: calc(100vh + 3.6rem) !important;
      overflow: hidden !important;
      zoom: 0.965 !important;
  }

}